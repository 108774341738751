import gql from "graphql-tag";

export const STORE_LOCATIONS = gql`
  query ($cart_id: String){
    storeLocations (cart_id: $cart_id){
      address
      city
      country
      openingDays: opening_days
      phoneNumber: phone_number
      state
      storeName: store_name
      storepickupId: storepickup_id
      workingHours: working_hours
      zipcode
      additionalInformation:additional_information
    }
  }
`;

export const GET_PRODUCT_SLIDER = gql`
    query productsSlider($condition: String!, $pageSize: Int, $sort: String = "position_by_sku", $category: Int) {
        productsByCondition(condition: $condition, pageSize: $pageSize, sort: $sort, category: $category) {
            __typename
            total_count
            items {
                __typename
                sku
                uid
                id
                name
                days_to_delivery
                is_saleable
                lifetime_status
                categories{
                    __typename
                    name
                }
                gallery {
                    disabled
                    disabled_default
                    file
                    id
                    label
                    label_default
                    large_image_url
                    media_type
                    medium_image_url
                    hover_image_url
                    position
                    position_default
                    row_id
                    small_image_url
                    url
                    value_id
                    is_hover
                    is_thumbnail
                    is_inspiration
                }
                canonical_url
                guarantees {
                    guarantee_description
                    guarantee_img_url
                }
                url_key
                emlabels {
                    label_text
                    attribute_code
                    priority
                    product_position
                    category_position
                    label_image
                    href
                    description
                }
                special_price
                price_last_30
                prices {
                    amount
                    unit
                    bg_color
                    currency_symbol
                    label
                    description
                    old_amount
                    percentage
                    price_type
                    promotion_end
                    promotion_start
                    txt_color
                }
                price_range{
                    minimum_price{
                        final_price{
                            currency
                            value
                        }
                    }
                    maximum_price{
                        final_price{
                            currency
                            value
                        }
                    }
                }
                advanced_product_inventory {
                    __typename
                    is_qty_decimal
                    use_config_enable_qty_inc
                    enable_qty_increments
                    use_config_qty_increments
                    qty_increments
                    use_config_min_sale_qty
                    min_sale_qty
                    unit
                }
            }
        }
    }`;
