import React, { useContext } from 'react';
import './Login.scss';
import { T } from '@deity-io/falcon-i18n';

const Delete = () => {
  return (
    <div className="customer">
      <T id="login.delete" />
    </div>
  );
};

export default Delete;
