import gql from "graphql-tag";

export const SHARE_WISHLIST = gql`
    mutation shareWishlist(
        $wishlistId: String!
        $emailList: String!
        $message: String!
    ) {
        shareWishlist(
            wishlistId: $wishlistId
            emailList: $emailList
            message: $message
        )
    }
`;

export const SHARED_WISHLIST = gql`
    query sharedWishlist($sharingCode: String!) {
        sharedWishlist(sharingCode: $sharingCode) {
            name
            items {
                __typename
                product {
                    __typename
                    name
                    canonical_url
                    gallery {
                        disabled
                        disabled_default
                        id
                        label
                        label_default
                        large_image_url
                        media_type
                        medium_image_url
                        hover_image_url
                        position
                        position_default
                        small_image_url
                        url
                        is_hover
                        is_thumbnail
                    }
                }
            }
        }
    }
`;

export const GET_WISHLIST_PRODUCTS = gql`
    query getWishlistProducts ($currentPage: Int, $pageSize: Int){
        getWishlistProducts(
            currentPage: $currentPage
            pageSize: $pageSize
        ) {
            wishlists {
                id
                items_v2 {
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                    items {
                        id
                        product {
                            __typename
                            sku
                            uid
                            id
                            name
                            days_to_delivery
                            is_saleable
                            lifetime_status
                            gallery {
                                disabled
                                disabled_default
                                file
                                id
                                label
                                label_default
                                large_image_url
                                media_type
                                medium_image_url
                                hover_image_url
                                position
                                position_default
                                row_id
                                small_image_url
                                url
                                value_id
                                is_hover
                                is_thumbnail
                            }
                            canonical_url
                            guarantees {
                                guarantee_description
                                guarantee_img_url
                            }
                            url_key
                            emlabels {
                                label_text
                                attribute_code
                                priority
                                product_position
                                category_position
                                label_image
                                href
                                description
                            }
                            hide_quarticon
                            special_price
                            price_last_30
                            prices {
                                amount
                                unit
                                bg_color
                                currency_symbol
                                label
                                description
                                old_amount
                                percentage
                                price_type
                                promotion_end
                                promotion_start
                                txt_color
                            }
                            advanced_product_inventory {
                                __typename
                                is_qty_decimal
                                use_config_enable_qty_inc
                                enable_qty_increments
                                use_config_qty_increments
                                qty_increments
                                use_config_min_sale_qty
                                min_sale_qty
                                unit
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ADD_PRODUCT_TO_WISHLIST = gql`
    mutation addProductsToWishlist(
      $wishlistId: ID!
      $wishlistItems: [WishlistItemInput!]!
    ) {
      addProductsToWishlist(
        wishlistId: $wishlistId
        wishlistItems: $wishlistItems
      ) {
        wishlist {
          id
        }
      }
    }
`

export const REMOVE_PRODUCT_FROM_WISHLIST = gql`
    mutation removeProductsFromWishlist(
        $wishlistId: ID!
        $wishlistItemsIds: [ID!]!
    ) {
        removeProductsFromWishlist(
            wishlistId: $wishlistId
            wishlistItemsIds: $wishlistItemsIds
        ) {
            user_errors {
                code
                message
            }
        }
    }
`

export const START_EXPORT = gql`
    mutation {
        exportInitialize {
            status
            message
        }
    }
`

export const DATA_EXPORT = gql`
    query {
        exportDownload {
            message
            status
            file {
                content
                name
            }
        }
    }
`